.order-con {
	@include flex-center;
	hr {
		color: #000;
		opacity: 100%;
	}
	.order {
		background-color: $lightBrown;
		@include border;
		@include borderRadiusContainers;
		padding: 1%;
	}
	.orderInfo {
		@include flex-between;
		flex-direction: row;
		h4 {
			color: $yellow;
			text-align: center;
		}
	}
	.conProducts {
		flex-direction: row;
		.prices {
			float: right;
			color: $yellow;
			font-weight: bold;
		}
		.qty {
			margin-left: 1%;
		}
		.text-muted {
			color: #8e8e8e !important;
		}
	}
	.total {
		text-align: right;
		color: $yellow;
	}

	.centerInfo {
		@include button;
		&.with-shipping{
			@include flex-between();			
		}
		&.without-shipping{
			@include flex-center();
			flex-direction: row;
			div{
				width: 50%;
				div{
					width: 100%;
				}
			}
		}
		div {
			text-align: center;
		}
	}

	.centerBtn{
		@extend.centerInfo;
		@include flex-between();
	}
}