.loading-container {
	@include flex-center;
}

@keyframes ldio-t5ijoz38lif {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.ldio-t5ijoz38lif div {
	left: 113.4px;
	top: 35.28px;
	position: absolute;
	animation: ldio-t5ijoz38lif linear 0.8130081300813008s infinite;
	background: #e5cd75;
	width: 25.2px;
	height: 55.44px;
	border-radius: 12.6px / 27.72px;
	transform-origin: 12.6px 90.72px;
}
.ldio-t5ijoz38lif div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -0.7317073170731707s;
	background: #e5cd75;
}
.ldio-t5ijoz38lif div:nth-child(2) {
	transform: rotate(36deg);
	animation-delay: -0.6504065040650406s;
	background: #06a6a8;
}
.ldio-t5ijoz38lif div:nth-child(3) {
	transform: rotate(72deg);
	animation-delay: -0.5691056910569106s;
	background: #e5cd75;
}
.ldio-t5ijoz38lif div:nth-child(4) {
	transform: rotate(108deg);
	animation-delay: -0.4878048780487805s;
	background: #06a6a8;
}
.ldio-t5ijoz38lif div:nth-child(5) {
	transform: rotate(144deg);
	animation-delay: -0.4065040650406504s;
	background: #e5cd75;
}
.ldio-t5ijoz38lif div:nth-child(6) {
	transform: rotate(180deg);
	animation-delay: -0.3252032520325203s;
	background: #06a6a8;
}
.ldio-t5ijoz38lif div:nth-child(7) {
	transform: rotate(216deg);
	animation-delay: -0.24390243902439024s;
	background: #e5cd75;
}
.ldio-t5ijoz38lif div:nth-child(8) {
	transform: rotate(252deg);
	animation-delay: -0.16260162601626016s;
	background: #06a6a8;
}
.ldio-t5ijoz38lif div:nth-child(9) {
	transform: rotate(288deg);
	animation-delay: -0.08130081300813008s;
	background: #e5cd75;
}
.ldio-t5ijoz38lif div:nth-child(10) {
	transform: rotate(324deg);
	animation-delay: 0s;
	background: #06a6a8;
}
.loadingio-spinner-spinner-la1rcf32xa {
	width: 252px;
	height: 252px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-t5ijoz38lif {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-t5ijoz38lif div {
	box-sizing: content-box;
}
/* generated by https://loading.io/ */