@import "../src/index.scss";

@import "./Styles/links.scss";

.error,
.invalid-feedback,
.required {
	color: $error !important;
	font-weight: bolder !important;
	margin-bottom: 0.1% !important;
	text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
		0.5px 0.5px 0 #000;
}

.errorCenter {
	@include flex-center;
	font-size: 1.3em;
}

.lightError {
	text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
		0.5px 0.5px 0 #000;
	font-size: 1em;
}

.capstone {
	color: $yellow;
}

.navbar {
	background-color: $darkbrown;
	a {
		color: $color;
		cursor: pointer;
	}
	a:hover {
		color: #9c9c9c;
	}
	img {
		max-width: 70px;
	}
	img:hover {
		opacity: 0.6;
	}
	border-bottom: 2px solid $borderbrown;
	box-shadow: 2px 2px $borderbrown;
	.navbar-toggler:focus {
		color: $color;
	}
	.navbar-toggler {
		border: 3px $color solid;
		background-color: $color;
	}
	.signin {
		margin-right: 15px;
	}
	.welcome {
		margin-right: 15px;
		cursor: default;
		text-decoration: none;
		list-style-type: none;
		a:hover {
			color: $color !important;
		}
	}
	.dropdown-menu {
		background-color: $darkbrown;
	}
	.dropdown-item:hover {
		background-color: $borderbrown !important;
		color: $yellow !important;
	}
}

body {
	color: $color;
	font-family: "Source Sans Pro", sans-serif;
	width: 100%;
	font-size: 1.2em;
}

h1 {
	font-size: 3em;
}

h1,
h2 {
	font-family: "Satisfy", cursive;
	color: $yellow;
	text-align: center;
}

h2 {
	font-size: 2em;
}

.carousel {
	@include flex-center();
	margin: 3%;

	img {
		width: 100%;
		@include border();
		@include borderRadiusContainers;
	}
	.carousel-control-next:hover,
			.carousel-control-prev:hover {
				background-color: rgba($color: $borderbrown, $alpha: 0.7);
			}
}

.layout {
	padding-bottom: 100px;
}

footer {
	background-color: $darkbrown;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	padding: 0.5%;
	border-top: 2px solid $borderbrown;
	box-shadow: 2px 2px $borderbrown;
	z-index: 900;
	b {
		color: $error;
	}
}

.intro-txt {
	@include flex-center;
	margin-top: 2%;
	div {
		@include border();
		@include borderRadiusContainers;
		background-color: $lightBrown;
		padding: 0.5% 1%;
		max-width: 70%;
	}
}

.services,
.events {
	margin: 1%;
	padding: 0.5%;
	div {
		@include flex-center;
		li {
			list-style-type: none;
		}
	}
}

.servicesPage {
	@include button;
}

.about-txt {
	@include flex-center();
	div {
		@include flex-center();
		@include border();
		@include borderRadiusContainers;
		background-color: $lightBrown;
		padding: 1%;
		margin: 1%;
	}
	img {
		max-width: 35% !important;
		margin-right: 2%;
		@include border();
		@include borderRadiusContainers;
	}
}

.modal-content {
	background-color: $lightBrown;
	@include button;
	.btn-close {
		background-color: $lightBrown;
		&:hover {
			background-color: $lightBrown;
		}
		&:active {
			background-color: $lightBrown;
		}
	}
}

@import "./Styles/contact.scss";

.row {
	width: 100%;
}

.album {
	@include flex-center();
}

.s-container {
	width: 90%;

	.card {
		margin-top: 3%;
		background-color: $lightBrown;
		color: $color;
		height: 100%;
		@include border();
		@include borderRadiusContainers;
		h5 {
			color: $yellow;
			font-weight: bold;
		}
		.card-body {
			// max-height: 500px;
			ul {
				list-style: none;
			}
		}
		button {
			margin-left: 35%;
			@include button;
		}
	}
}

.product {
	@include button;
	h3,
	h4 {
		text-align: center;
		color: $yellow;
	}
	div {
		text-align: center;
		.customQty {
			width: 40px;
			@include border-radius;
		}
		button {
			margin-left: 2% !important;
		}
	}
}

.team {
	.info {
		display: flex;
		p {
			margin-top: 5%;
			margin-left: 2%;
		}
	}
	h2 {
		text-align: left;
	}
	img {
		max-width: 500px;
		max-height: 500px;
		@include border;
		@include borderRadiusContainers;
	}
	.teammember {
		background-color: $lightBrown;
		@include border;
		@include borderRadiusContainers;
		margin: 3%;
		padding: 1%;
		max-width: 65%;
		margin-left: 20%;
	}
	.name {
		margin-left: 5%;
	}
}

.login,
.signup,
.booking {
	.form {
		margin: 5%;
		@include flex-center;
		@include button;
		div {
			background-color: $darkbrown;
			padding: 3%;
			border: 5px solid $borderbrown;
			border-radius: 5px;
			@include borderRadiusContainers;
		}
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="tel"],
		textarea,
		select {
			@include borderRadius;
			margin: 1%;
			width: 100%;
		}
		button {
			width: 100%;
			margin: 1%;
		}
		input[type="checkbox"] {
			margin: 1%;
		}
		.show_pass {
			width: 100%;
		}
		.no-border {
			border: none;
		}
		.sub {
			text-align: center;
			color: $yellow;
			font-weight: bold;
			font-size: 1em;
		}
	}
}

.signup {
	div {
		margin: -2%;
	}
}

.container {
	max-width: 960px;
	h4 {
		color: $yellow;
	}
	.checkoutform {
		@include button;
		@include border();
		@include borderRadiusContainers;
		background-color: $lightBrown;
		.text-muted {
			color: #8e8e8e !important;
		}
		.productDiv {
			max-height: 78% !important;
		}
		.cart {
			background-color: $darkbrown;
			@include border-brown;
			@include borderRadiusContainers;
			@include button;
			max-height: 78%;
			overflow: scroll;
		}
		.list-group-item {
			background-color: $darkbrown;
			color: $color;
			border-color: black;
		}
		.total {
			color: $yellow;
		}
		.hidden{
			display: none;
		}
	}
	.checkoutBtn {
		@include button;
		@include flex-center;
		button {
			@include button;
			max-width: 15vw;
			padding: 0.5%;
		}
	}
}

.cart {
	background-color: $darkbrown;
	@include border-brown;
	@include borderRadiusContainers;
	@include button;
	small,
	span {
		color: $color !important;
	}
	.list-group-item {
		background-color: $darkbrown;
		color: $color;
		border-color: black;
	}
	.total {
		color: $yellow;
	}

	.prices {
		color: $yellow;
		float: right;
	}

	.bi {
		cursor: pointer;
	}

	.bi:hover {
		filter: brightness(50%);
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
	}
}

.review {
	h3 {
		color: $yellow;
	}
	.reviewCart {
		@extend .cart;
		background-color: $lightBrown;
		border: 0px;
		border-radius: 0px;
		.list-group-item {
			background-color: $lightBrown;
			border-color: 0px;
			border-bottom-color: black;
		}
	}
	.lastProductHr {
		color: #000;
		opacity: 100%;
	}
}

.clientPicBtn {
	@include button;
	@include flex-center;
}

.pics {
	@include flex-center;
	flex-wrap: wrap;
	margin-bottom: 1%;

	a {
		margin: 1%;
	}

	img {
		max-width: 500px;
		cursor: pointer;
		filter: brightness(100%);
	}

	img:hover {
		filter: brightness(50%);
		-webkit-transition: all 1s ease;
		-moz-transition: all 1s ease;
		-o-transition: all 1s ease;
		-ms-transition: all 1s ease;
		transition: all 1s ease;
	}

	.delImage {
		@include button;
		margin-left: 35%;
	}

	div {
		margin: 1%;
		img {
			max-width: 500px;
			cursor: default;
			filter: brightness(100%);
		}

		img:hover {
			filter: brightness(100%);
			-webkit-transition: all 1s ease;
			-moz-transition: all 1s ease;
			-o-transition: all 1s ease;
			-ms-transition: all 1s ease;
			transition: all 1s ease;
		}
	}
}

.admincontainer {
	@include button;
	.user {
		@include border();
		@include border-radius();
		@include borderRadiusContainers();
		background-color: $lightBrown;
		width: 30vw;
		padding: 2%;
		margin: 2%;
	}
	.users {
		@include flex-center();
		flex-direction: row;
		flex-wrap: wrap;
	}
	.adminbtns {
		@include flex-center;

		flex-direction: row;
		div {
			width: 50%;
			@include flex-center;
		}
		button {
			font-size: 1em;
			padding: 1%;
			margin: 0%, 7%;
		}
	}
	.userMsg {
		text-align: center;
		font-size: 1.4em;
		color: $yellow;
	}
	.adminSearch {
		@include flex-center;
		div {
			width: 35%;
			@include border;
			@include borderRadiusContainers;
			background-color: $lightBrown;
			padding: 0.5%;
			input {
				@include borderRadius;
				margin: 1%;
				width: 98%;
			}
		}
	}

	.filterBookings {
		@include flex-center;
		div {
			select {
				background-color: $blue;
				@include borderRadius;
				border: none;
				padding: 2%;
				cursor: pointer;
			}
		}
	}
}

.editImgsCont {
	@include button;
	.upload__image-wrapper {
		div {
			@include flex-center();
			flex-direction: row;
			flex-wrap: wrap;
			.flex {
				@include flex-center();
				flex-direction: row;
				flex-wrap: wrap;
			}
		}
	}
	.btnDiv {
		flex-direction: block;
		.buttons {
			@include flex-center;
		}
	}

	.image-item {
		@include border();
		@include border-radius();
		@include borderRadiusContainers();
		background-color: $lightBrown;
		width: 20vw;
		padding: 2%;
		margin: 1%;
	}
	img {
		width: 260px;
		float: center;
	}
}

.errorpg {
	@include flex-center();
	flex-direction: row;
	h1 {
		width: fit-content;
		margin-left: 2%;
		margin-bottom: 4%;
	}
	img {
		text-align: left;
		margin: 2%;
		@include border();
		@include borderRadiusContainers;
	}
	a {
		background-color: $blue;
		border: none;
		padding: 1% 2%;
		margin: 2%;
		@include borderRadius();
		color: black;
		text-decoration: none;
		margin-left: 25%;
		margin-top: 1%;
	}
	a:hover {
		background-color: $blue-hover;
	}
	a:active {
		background-color: $blue-active;
	}
}

.role_flex {
	@include flex-center();
	margin: 2%;
	.checkbox_form {
		@include borderRadiusContainers;
		@include border-brown;
		@include button;
		background-color: $lightBrown;
		padding: 2%;
		label,
		input {
			cursor: pointer;
		}
		.form-check {
			margin: none !important;
		}
		.disabled {
			cursor: not-allowed;
		}
		input:hover {
			background-color: $yellow;
		}
		button {
			padding: 4%;
			margin: 4%;
		}
	}
}

.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
	@include button();
	.popup {
		background-color: $darkbrown;
		@include border-brown;
		@include borderRadiusContainers;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		position: relative;
		width: 300px;
		max-width: 80%;
	}
	.close-button {
		width: 100%;
		cursor: pointer;
		font-size: 18px;
	}
	p {
		margin: 0;
	}
}

@import "./Styles/order.scss";

@import "./Styles/bookingInfo.scss";

.react-datepicker__day--holidays {
	background-color: #9e7b9b !important;
}

.footer {
	.container {
		max-width: 100%;
		font-size: medium;
		span {
			margin-right: 6.3%;
		}
	}
}

@import "./Styles/loading.scss";

.scrollBtn {
	position: fixed;
	bottom: 50px;
	right: 20px;
	padding: 10px;
	outline: none;
	background-color: $blue;
	color: $black;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	z-index: 100;
	&:hover {
		background-color: $blue-hover;
		color: $black;
	}
}

.scrollBtnMobile {
	position: fixed;
	bottom: 50px;
	right: 13px;
	// padding: 10px;
	outline: none;
	color: $blue;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-size: 30px;
	&:hover {
		color: $blue-hover;
	}
}

@import "./Styles/media.scss";
