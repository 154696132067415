$color: #e5e5e5;
$blue: #06A6A8;
$yellow: #e5cd75;
$lightBrown: #4f473d;
$darkbrown: #2C261F;
$error: #FF6666;
$blue-hover: #057273;
$blue-active: #56C2C4;
$borderbrown: #242019;
$fc-border-color: black !important; 
$fc-event-bg-color: #06A6A8 !important;
$fc-event-border-color: #06A6A8 !important;
$fc-event-text-color: black !important;
$fc-button-active-bg-color: #32B5B7 !important;
$fc-button-hover-bg-color: #56C2C4 !important;
$bs-form-valid-color: #006400 !important;
$bs-form-valid-border-color: #006400 !important;

@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Source+Sans+Pro&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css");
@import "../node_modules/bootstrap/scss/bootstrap";


html,
body {
  position: relative;
  height: auto;
  width: 100%;
}

body{
  background-image: url("../src/background.jpg");
}

::selection{
  background-color: $blue !important;
  color: black !important;
}

::-moz-selection{
  background-color: $blue !important;
  color: black !important;
}

input:focus{
  outline: 3px solid $blue !important;
}

textarea:focus{
  outline: 3px solid $blue !important;
}

// from W3Schools
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@mixin flex-center{
  display: flex;
  align-content: center;
  justify-content: center;
}

@mixin flex-between{
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

@mixin border{
  border: 5px solid $darkbrown;
  border-radius: 5px;
}

@mixin borderRadius{
  border-radius: 5px;
}
    
@mixin borderRadiusContainers{
  border-radius: 10px;
}

@mixin border-brown{
  border: 5px solid $borderbrown;
}

@mixin button{
  button {
    background-color: $blue;
    border: none;
    padding: 1% 2%;
    margin: 2%;
    @include borderRadius();
  }
  button:hover {
    background-color: $blue-hover;
  }
  button:active {
    background-color: $blue-active;
  }
}