a {
	color: $blue;
	font-weight: bold;
}
a:hover {
	color: $blue-hover;
	font-weight: bold;
}
a:active {
	color: $blue-active;
	font-weight: bold;
}
a:visited {
	color: $blue;
	font-weight: bold;
}