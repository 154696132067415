@media all and (max-width: 1694px) {
	.about-txt {
		display: block;
		div {
			display: block;
		}
		img {
			margin-right: 50% !important;
			max-width: 50% !important;
		}
	}
}

@media all and (max-width: 810px) {
	.contact {
		.flex {
			display: block;
			margin-left: 0%;
			margin: 1%;
			.form {
				width: 90vw;
				margin-left: 4%;
			}
		}
	}
	.fc {
		width: 90vw;
		margin-left: 4%;
		margin-bottom: 2%;
	}
	.s-container {
		display: flex;
		justify-content: space-between;
		.card {
			flex: 1; /* This will make both columns equally sized */
			flex-basis: 49%;
		}
	}
	.info {
		display: block !important;
	}
	.admincontainer {
		.user {
			width: 96vw;
			text-align: center;
		}
		.users {
			display: block;
		}
		h3{
			text-wrap: wrap;
		}
	}
	.editImgsCont {
		.upload__image-wrapper {
			div {
				display: block;
				.flex {
					margin-right: 35%;
				}
			}
			.image-item {
				width: 55vw;
				padding: 2%;
				margin: 1%;
			}
			img {
				width: 260px;
				float: center;
			}
		}
	}
}

@media all and (max-width: 579px) {
	.centerInfo {
		@include button;
		&.with-shipping{
			@include flex-between();	
			flex-direction: column;
			div{
				margin-bottom: 3%;
			}

		}
		&.without-shipping{
			@include flex-center();
			flex-direction: column;
			div{
				width: 50%;
				margin-bottom: 3%;
				div{
					width: 100%;
				}
			}
		}
		div {
			text-align: center;
		}
	}
	.centerBtn{
		flex-direction: column;
	}
	.about-txt{
		text-align: center;
		img{
			width: 70vw;
			margin-left: 25%;
			margin-bottom: 5%;
		}
	}
}

// center about txt
@media all and (max-width: 428px) {	
	.editImgsCont {
		.upload__image-wrapper {
			div {
				display: block;
				.flex {
					margin-right: 35%;
				}
			}
			.image-item {
				width: 90vw;
				padding: 2%;
				margin: 1%;
			}
			img {
				width: 260px;
				float: center;
			}
		}
	}
	footer {
		background-color: $darkbrown;
		position: relative; /* Change this to relative */
		width: 100%;
		padding: 0.5%;
		border-top: 2px solid $borderbrown;
		box-shadow: 2px 2px $borderbrown;
		z-index: 900;
		b {
			color: $error;
		}
	}
	.info {
		img {
			width: 100%;
		}
	}
	.pics {
		img {
			width: 90vw;
		}
	}
	.editImgsCont {
		.upload__image-wrapper {
			div {
				display: block;
				.flex {
					margin-right: 35%;
				}
			}
			.image-item {
				width: 90vw;
				padding: 2%;
				margin: 1%;
			}
			img {
				width: 260px;
				float: center;
			}
		}
	}
	.intro-txt {
		div{
			max-width: 95%;
		}
	}
	.team{
		.teammember{
			max-width: 95%;
			margin-left: 3%;
		}
	}
	.moveItOver{
		margin-left: 6%;
		.checkoutBtn{
		button{
			max-width: 100vw;
		}
		
	}
	}
	
}
